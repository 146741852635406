import React from "react";

function About() {
    return (
        <section className="mh-about" id="mh-about">
            <div className="container">
                <div className="row section-separator">
                    <div className="col-sm-12 col-md-6">
                        <div className="mh-about-img shadow-2 wow fadeInUp" data-wow-duration="0.8s"
                             data-wow-delay="0.4s">
                            <img src="/assets/images/comp.jpg" alt="" className="img-fluid"/>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mh-about-inner">
                            <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s">About Me</h2>
                            <p className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">Hello, I’m
                                Favour, a software developer.
                                I have rich experience in software architecture and design,
                                backend development and database management. Also I am good at 👇</p>
                            <div className="mh-about-tag wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">
                                <ul>
                                    <li><span>Python</span></li>
                                    <li><span>Node.js</span></li>
                                    <li><span>Java</span></li>
                                    <li><span>Javascript</span></li>
                                    <li><span>Angular</span></li>
                                    <li><span>PosgreSQL</span></li>
                                    <li><span>ElasticSearch</span></li>
                                    <li><span>AWS</span></li>
                                    <li><span>Docker</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;