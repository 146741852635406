import React from "react";

function Home() {
    return (
        <section className="mh-home image-bg relative" id="mh-home">
            <div className="img-foverlay img-color-overlay">
                <div className="container">
                    <div className="row section-separator xs-column-reverse vertical-middle-content home-padding">
                        <div className="col-sm-6">
                            <div className="mh-header-info">
                                <div className="mh-promo wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s">
                                    <span>Hello I'm</span>
                                </div>

                                <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">Favour Okedele</h2>
                                <h4 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">Senior Software Developer</h4>

                                <ul>
                                    <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.4s"><i
                                        className="fa fa-envelope"/><a target="_blank" href="mailto:favouroked@gmail.com">favouroked@gmail.com</a></li>
                                    <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.5s"><i
                                        className="fa fa-phone"/><a href="tel:+2349038043017">+234 903 804 3017</a></li>
                                    <li className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s"><i
                                        className="fa fa-map-marker"/>
                                        <address>Ibadan, Oyo State, Nigeria</address>
                                    </li>
                                </ul>

                                <ul className="social-icon wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.7s">
                                    <li><a href="https://ng.linkedin.com/in/favour-okedele"><i className="fa fa-linkedin"/></a></li>
                                    <li><a href="https://twitter.com/favour_nifemi"><i className="fa fa-twitter"/></a></li>
                                    <li><a href="https://github.com/Favouroked"><i className="fa fa-github"/></a></li>
                                    <li><a href="https://medium.com/@favouroked"><i className="fa fa-medium"/></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="hero-img wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.6s">
                                <div className="img-border">
                                    <img src="/assets/images/myBlavatar.png" alt="" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Home;

