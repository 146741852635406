import React from 'react';
import $ from 'jquery';

class Header extends React.Component {


    handleActive(){
        Promise.all([
            $(".overlay").toggleClass("active"),
            $("#navbarSupportedContent").toggleClass("active")
        ]);
    }

    render() {
        return (
            <header className="black-bg mh-header mh-fixed-nav nav-scroll mh-xs-mobile-nav" id="mh-header">
                <div className="overlay"/>
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-expand-lg mh-nav nav-btn">
                            <a className="navbar-brand" href="/">
                                <img src="/assets/images/myBlavatar-69x69.png" alt="" className="img-fluid"/>
                            </a>
                            <button onClick={e => this.handleActive()} className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon icon"/>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-0 ml-auto">
                                    <li className="nav-item active">
                                        <a className="nav-link" href="#mh-home">Home</a>
                                    </li>
                                    <li className="nav-item" onClick={e => this.handleActive()}>
                                        <a className="nav-link" href="#mh-about">About</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#mh-experience">Experiences</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" target="_blank"
                                           href="https://blog.favouroked.me">Blog</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="#mh-contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header;
