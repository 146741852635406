import React from 'react';
import './App.css';

import Header from "./components/Header";
import Home from "./components/Home";
import SectionLoader from "./components/loaders/SectionLoader";
import About from "./components/About";
import Experiences from "./components/Experiences";
import ContactMe from "./components/ContactMe";

import HOC from "./components/hoc/HOC";

function App() {
    return (
        <HOC>
            <SectionLoader/>
            <Header/>
            <Home/>
            <About/>
            <Experiences/>
            <ContactMe/>
        </HOC>
    );
}

export default App;
