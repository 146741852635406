import React from "react";

function Experiences() {
    return (
        <section className="mh-experince image-bg" id="mh-experience">
            <div className="img-color-overlay">
                <div className="container">
                    <div className="row section-separator">
                        <div className="col-sm-12 col-md-6">
                            <div className="mh-education">
                                <h3 className="wow fadeInUp" data-wow-duration="0.8s"
                                    data-wow-delay="0.2s">Featured Projects</h3>
                                <div className="mh-education-deatils">
                                    <div className="mh-education-item dark-bg wow fadeInUp" data-wow-duration="0.8s"
                                         data-wow-delay="0.3s">
                                        <h4><a href="https://seekhostel.com">Seekhostel</a></h4>
                                        <div className="mh-eduyear">2018</div>
                                        <p>A web application that helps students to find quality accommodation.
                                            Seekhostel was created to help students get the best accommodation at
                                            affordable price at the comfort of their rooms.
                                        </p>
                                    </div>
                                    <div className="mh-education-item dark-bg wow fadeInUp" data-wow-duration="0.8s"
                                         data-wow-delay="0.5s">
                                        <h4><a href="https://meritflow.tech">Meritflow</a></h4>
                                        <div className="mh-eduyear">2019</div>
                                        <p>This is a savings and loans platform that can lend you money which you
                                            can pay in installments. Users can also create a locked savings plan where
                                            they can save money and then withdraw it at a specified date.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mh-work">
                                <h3>Work Experience</h3>
                                <div className="mh-experience-deatils">
                                    <div className="mh-work-item dark-bg wow fadeInUp" data-wow-duration="0.8s"
                                        data-wow-delay="0.4s">
                                        <h4>Senior Software Engineer <a href="">PacketFabric</a></h4>
                                        <div className="mh-eduyear">May 2022-May 2023</div>
                                        <span>Responsibility :</span>
                                        <ul className="work-responsibility">
                                            <li><i className="fa fa-circle"></i>Wrote core platform code for a new feature, unit tests for functionality.</li>
                                            <li><i className="fa fa-circle"></i>Refactored and improved existing code for performance and simplicity.</li>
                                            <li><i className="fa fa-circle"></i>Built tools to help our internal business partners better perform their jobs.</li>
                                            <li><i className="fa fa-circle"></i>Occasional on call support rotation to assist in escalation from support.</li>
                                        </ul>
                                    </div>
                                    <div className="mh-work-item dark-bg wow fadeInUp" data-wow-duration="0.8s"
                                         data-wow-delay="0.4s">
                                        <h4>Full Stack Engineer <a href="">Kudi</a></h4>
                                        <div className="mh-eduyear">Sep 2019-May 2022</div>
                                        <span>Responsibility :</span>
                                        <ul className="work-responsibility">
                                            <li><i className="fa fa-circle"></i>Backend Web Development</li>
                                            <li><i className="fa fa-circle"></i>Database Management</li>
                                            <li><i className="fa fa-circle"></i>Data Engineering</li>
                                            <li><i className="fa fa-circle"></i>Frontend Web Development</li>
                                        </ul>
                                    </div>
                                    <div className="mh-work-item dark-bg wow fadeInUp" data-wow-duration="0.8s"
                                         data-wow-delay="0.6s">
                                        <h4>Software Engineer<a href=""> Terragon Group</a></h4>
                                        <div className="mh-eduyear">May 2018 - Sep 2019</div>
                                        <span>Responsibility :</span>
                                        <ul className="work-responsibility">
                                            <li><i className="fa fa-circle"></i>Backend Web Development</li>
                                            <li><i className="fa fa-circle"></i>Data Engineering</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Experiences;